import * as React from "react";
import {Header, Container, Segment, Icon, Grid} from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const TechnologyPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="server" />
          <Header.Content>
            Technology
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical className="stripe">
        <Grid stackable verticalAlign="middle" className="container">
          <Grid.Row>
            <Grid.Column width="7">
              <Header>UnStacked C Architecture</Header>
              <p>
                UnStacked C is a whole-program compiler that translates multithreaded source code into event driven source code. It is based off of another project called <a href={"http://www.cxmlc.com/"}>C-XML-C</a>. A stripped down copy of C-XML-C is included in the UnStacked C installation. C-XML-C is a project based off of <a href={"https://people.eecs.berkeley.edu/~necula/cil/"}>CIL</a>.
              </p>
            </Grid.Column>
            <Grid.Column width="7" floated="right">
              <Header>Stackless Multithreading</Header>
              <p>
                In traditional multithreading each thread has an individual stack including all state and local variables. This requires stack swapping routines to switch between threads.
                </p>
              <p>
                In stackless multithreading we compile an entire program's threads to move their states and variables outside of the stack -- enabling all threads to share a single stack.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* Key features */}
      <Segment vertical className="stripe alternate feature">
        <Grid columns="3" textAlign="center" divided relaxed stackable className="container">
          <Grid.Row>
            <Grid.Column>
              <Header icon>
                <Icon name="unlock"></Icon>
                Lazy Preemption
              </Header>
              <p>
                Lazy Preemption is a method of translating preemptive multithreads into cooperative multithreads.
                We implement UnStacked C with Lazy Preemption inside of UnStacked C.
              </p>
              <p>
                Lazy preemption essentially detects loops in threaded code, and adds conditional yields.
                With a timer to trigger the yields, these threads can now run concurrently on a cooperative scheduler.
                This avoids many datafaults caused by typical preemption methods.
              </p>
            </Grid.Column>
            <Grid.Column>
              <Header icon>
                <Icon name="text height"></Icon>
                Stack Analysis
              </Header>
              <p>
                At compile time Unstacked C generates a complete call graph of all functions in the application.
                It uses this information to translate thread local variables into nested structs.
              </p>
              <p>These nested structures effectively replaces the individual thread stacks -- and are optimally allocated without the risk of faults.</p>
            </Grid.Column>
            <Grid.Column>
              <Header icon>
                <Icon name="wizard"></Icon>
                Whole Program Transformation
              </Header>
              <p>
                To generate these changes, the entire application source code is compiled at once. Unstacked C compiles from multithreaded C code into event driven C code.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default withLayout(TechnologyPage);
